import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import './theme/plain.less'
import axios from 'axios'
import qs from 'qs'
import md5 from 'md5'
import vuescroll from 'vuescroll'
import common from './utils/common'
import moment from 'moment'
import configs from './../configs'
import Api from './api/Api'
import 'babel-polyfill'
import VueLazyLoad from 'vue-lazyload'

axios.defaults.withCredentials = true
Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.prototype.$md5 = md5
Vue.prototype.$common = common
Vue.prototype.$moment = moment
Vue.prototype.$configs = configs
Vue.prototype.$Api = Api

Vue.use(ViewUI)
Vue.use(vuescroll, {
  ops: {} // 在这里设置全局默认配置
})
Vue.use(VueLazyLoad)

Vue.config.productionTip = false

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      // if (instance) {
      //   let tokenFromStore = instance.$store.getters.token
      //   let tokenFromLocalStorage = window.localStorage.getItem('token')
      //   // console.log(token)
      //   let token = tokenFromStore ||tokenFromLocalStorage
      //   if (token && token !== null && config.url.indexOf('/api/Account/Login') === -1) {
      //     config.headers.Authorization = 'Bearer ' + token
      //   }
      // }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // let config = error.config
    // console.log(config)
    if (error.response) {
      // console.log(error.request, error.response.status)
      switch (error.response.status) {
        case 401:
          instance.$Spin.hide()
          instance.$Notice.error({
            title: '您还未登录，请先登录',
            desc: ''
          })
          router.push({ path: '/login' })
          break
        case 406:
          router.back()
          instance.$Spin.hide()
          instance.$Notice.error({
            title: '您没有访问该资源的权限！',
            desc: ''
          })
          break
      }
    }
    return Promise.reject(error.response.data.message)   // 返回接口返回的错误信息
  }
)

Vue.prototype.$vuescrollConfig = {
  bar: {
    background: '#777'
  }
}

let instance = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
