import Vue from 'vue'
import Vuex from 'vuex'
import messages from './MessageStore'
import UserStore from './UserStore'
import PageStore from './PageStore'
import keepAliveTags from './keepAliveTag'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    messages,
    UserStore,
    PageStore,
    keepAliveTags
  }
})
