const configsHenan = {
  title: '农民工工资担保风险预警系统',
  version: 'v2.0',
  subtitle: 'v2.0',
  copyRightHolder: '思创网联(北京)科技发展有限公司',
  siteStartYear: 2020,
  siteICPNo: '京ICP备16022875号',
  copyRightHolderHome: 'http://www.sclinker.com',
  hotline: '4008115272',
  techSupport: '思创网联(北京)科技发展有限公司',
  techSupportHome: 'https://www.sclinker.com',
  welcomeBackgroundImageUrl: '002.jpg', // 01.png
  enableRegister: false, // 是否开放注册
  loginLayoutName: '',
  aliyunOSSBucket: 'zhuzhu777-test',
  aliyunOSSPrefix: 'zzt'
}

const configs = configsHenan

const getSysConfig = (key) => {
  let value = null
  if (configs) {
    if (configs[key]) {
      return configs[key]
    } else {
      return value
    }
  } else {
    return value
  }
}

module.exports = getSysConfig
