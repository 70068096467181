import Auth from './Auth'
import Project from './project'
import Aliyun from './Aliyun'

const Api = {
  Auth (inst) {
    return new Auth(inst)
  },
  Project,
  Aliyun
}

export default Api