export default {
  state: {
    Name: '',
    IdNumber: '',
    MobilePhoneNumber: '',
    IcCardId: '',
    Permission: 1,
    Permissions: [],
    Roles: -1,
    Birthday: '',
    IsMale: true,
    Wechat: '',
    Email: '',
    Username: '',
    CreationTime: '',
    Token: ''
  },
  mutations: {
    SET_USER_STORE (state, data) {
      state.Name = data.Name
      state.IdNumber = data.IdNumber
      state.MobilePhoneNumber = data.MobilePhoneNumber
      state.IcCardId = data.IcCardId
      state.Permission = data.Permission
      state.Permissions = data.Permissions
      state.Roles = data.Roles
      state.Birthday = data.Birthday
      state.IsMale = data.IsMale
      state.Wechat = data.Wechat
      state.Email = data.Email
      state.Username = data.Username
      state.CreationTime = data.CreationTime
    },
    SET_TOKEN (state, data) {
      state.Token = data
    },
    LOGOUT (state) {
      state.Name = ''
      state.IdNumber = ''
      state.MobilePhoneNumber = ''
      state.IcCardId = ''
      state.Permission = 1
      state.Permissions = []
      state.Roles = -1
      state.Birthday = ''
      state.IsMale = true
      state.Wechat = ''
      state.Email = ''
      state.Username = ''
      state.CreationTime = ''
      state.Token = ''
    }
  },
  actions: {
    setUserStore ({ commit }, data) {
      commit('SET_USER_STORE', data)
    },
    setToken ({ commit }, data) {
      commit('SET_TOKEN', data)
    },
    logout ({ commit }) {
      commit('LOGOUT')
    }
  },
  getters: {
    userName: state => {
      return state.Username
    },
    idNumber: state => {
      return state.IdNumber
    },
    mobilePhoneNumber: state => {
      return state.MobilePhoneNumber
    },
    icCardId: state => {
      return state.IcCardId
    },
    permission: state => {
      return state.Permission
    },
    permissions: state => {
      return state.Permissions
    },
    roles: state => {
      return state.Roles
    },
    birthday: state => {
      return state.Birthday
    },
    isMale: state => {
      return state.IsMale
    },
    wechat: state => {
      return state.Wechat
    },
    email: state => {
      return state.Email
    },
    name: state => {
      return state.Name
    },
    creationTime: state => {
      return state.CreationTime
    },
    token: state => {
      return state.Token
    }
  }
}
