import base from './base'

let controller = 'ZztPrjBiz'
let action = null

const Project = {
  async getOverview () {
    action = 'Analysis/Overview'
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async getProjectStat (prjId) {
    action = 'Analysis/Project/' + prjId
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async listAllProjects () {
    action = 'List/All'
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async listAllProjectsWithAlarmAndComplaint () {
    action = 'List/AllWithAlarm'
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async addProject (model) {
    action = 'Add'
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: model
    }
    return base.request(reqModel)
  },

  async updateProject (request) {
    if (!request.Tid) {
      throw new Error('提交数据不合法!项目ID为空')
    }
    action = `Modify`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: request
    }
    return base.request(reqModel)
  },

  async updateProjectGeofence (request) {
    if (!request.Tid) {
      throw new Error('提交数据不合法!项目ID为空')
    }
    action = `Modify`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: request
    }
    return base.request(reqModel)
  },

  async getProject (projectId) {
    if (!projectId) {
      throw new Error('ProjectId为空')
    }
    action = `View/${projectId}`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true
    }
    return base.request(reqModel)
  },

  async getProjectWorkers (projectId) {
    if (!projectId) {
      throw new Error('ProjectId为空')
    }
    action = `Users/${projectId}/施工人员`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true
    }
    return base.request(reqModel)
  },

  async getProjectManagers (projectId) {
    if (!projectId) {
      throw new Error('ProjectId为空')
    }
    action = `Users/${projectId}/-施工人员`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true
    }
    return base.request(reqModel)
  },

  async getAllComplaints () {
    action = `Complaint/List`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true
    }
    return base.request(reqModel)
  },

  async getProjectComplaints (projectId) {
    if (!projectId) {
      throw new Error('ProjectId为空')
    }
    action = `Complaint/List/${projectId}`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true
    }
    return base.request(reqModel)
  },

  async getProjectComplaintDetail (complaintId) {
    if (!complaintId) {
      throw new Error('complaintId为空')
    }
    action = `Complaint/Show/${complaintId}`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true
    }
    return base.request(reqModel)
  },

  async closeProjectComplaint (complaintId) {
    if (!complaintId) {
      throw new Error('complaintId为空')
    }
    action = `Complaint/Close/${complaintId}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async replyProjectComplaint (replyModel) {
    action = `Complaint/Reply`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: replyModel
    }
    return base.request(reqModel)
  },

  async getAllAlarms () {
    action = `Alarm/List`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async getAlarmDetail (alarmId) {
    if (!alarmId) {
      throw new Error('alarmId为空')
    }
    action = `Alarm/Show/${alarmId}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async closeAlarm (alarmId) {
    if (!alarmId) {
      throw new Error('alarmId为空')
    }
    action = `Alarm/Close/${alarmId}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async addAlarm (model) {
    action = `Alarm/Add`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: model
    }
    return base.request(reqModel)
  },

  async replyAlarm (replyModel) {
    action = `Alarm/Reply`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: replyModel
    }
    return base.request(reqModel)
  },

  async addProjectUser (prjUserItem) {
    action = `Link/Add`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: prjUserItem
    }
    return base.request(reqModel)
  },

  async editProjectUser (prjUserItem) {
    action = `Link/Edit`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: prjUserItem
    }
    return base.request(reqModel)
  },

  async removeProjectUser (prjUserItem) {
    action = `Link/Remove`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: prjUserItem
    }
    return base.request(reqModel)
  },

  async getProjectAttendance (prjId, year, month) {
    action = `Attendance/${prjId}/${year}/${month}`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async getProjectUserAttendance (prjId, year, month, userId) {
    action = `Attendance/${prjId}/${year}/${month}/${userId}`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async getProjectUserAttendanceByDate (prjId, date, userId) {
    action = `SigninDetail/${prjId}/${userId}/${date}`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async AddReview (model) {
    action = 'UserReview/Add'
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: model
    }
    return base.request(reqModel)
  },

  // async getReview  (userId) {
  //   action = 'UserReview/List'
  //   let reqModel = {
  //     method: 'POST',
  //     controller: controller,
  //     action: action,
  //     auth: true,
  //     data: userId
  //     }
  //     return base.request(reqModel)
  //   },

  async getReviewUserId  (userId) {
    // if (!userId) {
    //   throw new Error('userId为空')
    // }
    action = `UserReview/List/${userId}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },
  
  async getReviewwithproject (userId, prjId) {
    // if (!userId) {
    //   throw new Error('userId为空')
    // }
    action = `UserReview/list/${userId}/${prjId}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async addTags (model) {
    action = `Tags/Add`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: model
    }
    return base.request(reqModel)
  },
  
  async editTags (model) {
    action = `Tags/Edit`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: model
    }
    return base.request(reqModel)
  },

  async removeTags (tagId) {
    action = `Tags/Delete/${tagId}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async getAllTags (PageIndex=1, PageSize=20) {
    action = `Tags/List`
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: PageIndex, PageSize
    }
    return base.request(reqModel)
  },

  async addUserTags (model) {
    action = `UserTags/Add`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: model
    }
    return base.request(reqModel)
  },

  async removeUserTags (model) {
    action = `UserTags/Remove`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: model
    }
    return base.request(reqModel)
  },

  async getIllegalReleaseList(model) {
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    if (model.PageIndex <= 0) {
      queryModel.PageIndex = 1
    }
    if (model.PageSize <= 0) {
      queryModel.PageSize = 20
    }

    action = `IllegalRelease/List/`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async addIllegalRelease(model) {
    action = `IllegalRelease/Add/`
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async editIllegalRelease(model) {
    action = `IllegalRelease/Edit/`
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async deleteIllegalRelease(id) {
    action = `IllegalRelease/Delete/${id}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: {}
    }
    return base.request(reqModel)
  },

  async getJointPunishmentList(model) {
    let queryModel = {}
    Object.assign(queryModel, model)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    if (model.PageIndex <= 0) {
      queryModel.PageIndex = 1
    }
    if (model.PageSize <= 0) {
      queryModel.PageSize = 20
    }

    action = `JointPunishmentNamelist/List/`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async addJointPunishment(model) {
    action = `JointPunishmentNamelist/Add/`
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async editJointPunishment(model) {
    action = `JointPunishmentNamelist/Edit/`
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async deleteJointPunishment(id) {
    action = `JointPunishmentNamelist/Delete/${id}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: {}
    }
    return base.request(reqModel)
  },

  async getRegulationList(model) {
    let queryModel = {}
    Object.assign(queryModel, model)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    if (model.PageIndex <= 0) {
      queryModel.PageIndex = 1
    }
    if (model.PageSize <= 0) {
      queryModel.PageSize = 20
    }

    action = `Regulation/List/`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async addRegulation(model) {
    action = `Regulation/Add/`
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async editRegulation(model) {
    action = `Regulation/Edit/`
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async deleteRegulation(id) {
    action = `Regulation/Delete/${id}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: {}
    }
    return base.request(reqModel)
  },

  async getComplaintTelList(model) {
    let queryModel = {}
    Object.assign(queryModel, model)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }

    action = `ComplaintTel/List/`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async addComplaintTel(model) {
    action = `ComplaintTel/Add/`
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async editComplaintTel(model) {
    action = `ComplaintTel/Edit/`
    let queryModel = {}
    Object.assign(queryModel, model)
    console.log(model.Province)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async deleteComplaintTel(id) {
    action = `ComplaintTel/Delete/${id}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: {}
    }
    return base.request(reqModel)
  },

  async getGeneralComplaintList(model) {
    let queryModel = {}
    Object.assign(queryModel, model)
    if (model.Province && model.Province > 0) {
      queryModel.Province = parseInt(model.Province)
    } else {
      queryModel.Province = 0
    }
    if (model.Municipality && model.Municipality > 0) {
      queryModel.Municipality = parseInt(model.Municipality)
    } else {
      queryModel.Municipality = 0
    }
    if (model.County && model.County > 0) {
      queryModel.County = parseInt(model.County)
    } else {
      queryModel.County = 0
    }

    action = `GeneralComplaint/List/`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: queryModel
    }
    return base.request(reqModel)
  },

  async setGeneralComplaintDoing(id) {
    action = `GeneralComplaint/Doing/${id}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: {}
    }
    return base.request(reqModel)
  },

  async setGeneralComplaintDone(model) {
    action = `GeneralComplaint/Done`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: model
    }
    return base.request(reqModel)
  },

  async setGeneralComplaintClose(id) {
    action = `GeneralComplaint/Close/${id}`
    let reqModel = {
      method: 'POST',
      controller: controller,
      action: action,
      auth: true,
      data: {}
    }
    return base.request(reqModel)
  },

}

export default Project