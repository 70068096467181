<template>
  <div class="home">
    <section>
      <h1>欢迎访问<small>{{$configs('title')}}</small></h1>
    </section>
    <div id="main">
      <Row>
        <Col span="6" style="padding:0 5px;margin:10px 0;">
          <div class="card-infobox" @click="goto('project')">
            <Icon class="infobox-icon" type="ios-cog-outline"></Icon>
            <p class="infobox-title">我的项目</p>
            <div class="infobox-content">
              <h2>{{projectCount}}</h2>项
            </div>
          </div>
        </Col>
        <Col span="6" style="padding:0 5px;margin:10px 0;" v-if="checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营','监管人'])">
          <div class="card-infobox" @click="goto('alarm')">
            <Icon class="infobox-icon" type="md-alert"></Icon>
            <p class="infobox-title">风险预警</p>
            <div class="infobox-content">
              <h2>{{alarmCount}}</h2>条
            </div>
          </div>
        </Col>
        <Col span="6" style="padding:0 5px;margin:10px 0;" v-if="checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营'])">
          <div class="card-infobox" @click="goto('complaint')">
            <Icon class="infobox-icon" type="ios-notifications"></Icon>
            <p class="infobox-title">欠薪投诉</p>
            <div class="infobox-content">
              <h2>{{complaintCount}}</h2>条
            </div>
          </div>
        </Col>
        <Col span="6" style="padding:0 5px;margin:10px 0;">
          <div class="card-infobox">
            <Icon class="infobox-icon" type="ios-people-outline"></Icon>
            <p class="infobox-title">队伍数量</p>
            <div class="infobox-content">
              <h2>{{teamCount}}</h2>个
            </div>
          </div>
        </Col>
        <Col span="6" style="padding:0 5px;margin:10px 0;">
          <div class="card-infobox">
            <Icon class="infobox-icon" type="ios-happy-outline"></Icon>
            <p class="infobox-title">工人数量</p>
            <div class="infobox-content">
              <h2>{{workerCount}}</h2>人
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {
  },
  data () {
    return {
      alarmCount: 0,
      complaintCount: 0,
      workerCount: 0,
      projectCount: 0,
      teamCount: 0,
      onSiteCount: 0
    }
  },
  mounted () {
    this.getOverview()
    // this.BaiduAIGroupList()
  },
  methods: {
    goto (view) {
      switch (view) {
        case 'project':
          this.$router.push('/projects/list')
          break
        case 'alarm':
          this.$router.push('/alarm/list')
          break
        case 'complaint':
          this.$router.push('/complaint/list')
          break
      }
    },
    checkRolePermitted (roles) {
      if (!this.$store.getters.permission || !this.$store.getters.permissions.length) {
        return false
      }
      if (this.$store.getters.permission % 2 === 0) {
        // 后台-管理员
        return true
      }
      let userRoles = []
      this.$store.getters.permissions.forEach((p) => {
        userRoles.push(this.$common.permissions[p.toString()])
      })
      if (roles instanceof Array) {
        let inside = false
        roles.forEach((r) => {
          if (userRoles.indexOf(r) > -1) {
            inside = true
          }
        })
        return inside
      }
      return userRoles.indexOf(roles) > -1
    },
    getOverview () {
      this.$Spin.show()
      this.$Api.Project.getOverview().then((respBody) => {
        this.$Spin.hide()
        this.projectCount = respBody.ProjectCount
        this.workerCount = respBody.MigrantWorkerCount
        this.teamCount = respBody.TeamCount
        this.onSiteCount = respBody.OnSiteCount
        this.complaintCount = respBody.ComplaintCount
        this.alarmCount = respBody.AlarmCount
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '获取个人信息失败!',
          desc: err.message
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './../theme/plain.less';
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: @primary-color;
}
.card-infobox {
  border-radius: 0;
  padding: 10px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100px;
  cursor: pointer;
  color: #ccc;
}
.card-infobox .infobox-title {
  color: #ccc;
  text-align: right;
  font-weight: 500;
  /*text-shadow: 1px 1px 0px #666;*/
  font-size: 16px;
  position: relative;
  transition: all .5s ease-in-out;
  z-index: 2;
}
.card-infobox:hover .infobox-title {
  color: #666;
}
.card-infobox .infobox-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  text-align: right;
  z-index: 2;
  flex:1;
}
.card-infobox .infobox-content h2 {
  display: inline;
  color: #999;
  font-size: 32px;
  padding: 0 10px;
}
.card-infobox .infobox-icon {
  font-size: 90px;
  color: @primary-color;
  opacity: .3;
  position: absolute;
  z-index: 0;
  left: -30px;
  transition: all .5s ease-in-out;
}
.card-infobox:hover .infobox-icon {
  opacity: .5;
  left: 15px;
  bottom: 0px;
}
</style>
