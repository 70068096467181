import base from './base'
import {base64} from './../utils/common'
import configs from './../../configs'

let controller = 'Aliyun'
let action = null

const Aliyun = {
  async getFileUrl (fileName) {
    if (fileName.indexOf('oss:') === 0) {
      fileName = fileName.substr(4)
    }
    let fileB64 = base64(configs('aliyunOSSBucket') + '/' + fileName)
    action = 'GetUrl2/' + fileB64
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  },

  async getUploadParams () {
    action = `RequestCommonUpload/${configs('aliyunOSSBucket')}/${configs('aliyunOSSPrefix')}`
    let reqModel = {
      method: 'GET',
      controller: controller,
      action: action,
      auth: true,
      data: null
    }
    return base.request(reqModel)
  }
}

export default Aliyun