const qs = require('qs')
const axios = require('axios').default

const getApiUrl = (req) => {
  let reqModel = {
    version: 1.0,
    urlWithVersion: false, // url中是否带version
    controller: String,
    action: String,
    queryString: Object
  }
  Object.assign(reqModel, req)
  let host = 'http://demo.zhuzhu777.com/'
  host = '/'
  // host = 'http://my.zhaoshuhang.cn:1581/'
  if (window.location.host.indexOf('localhost') > -1 ||
    window.location.host.indexOf('192.168') > -1) {
    // host = 'https://joint-test.toubiao51.com/'
  }
  let baseUrl = `${host}r`
  if (reqModel.urlWithVersion) {
    baseUrl += `/v${reqModel.version}`
  }
  // console.log(baseUrl)
  let url = `${baseUrl}/${reqModel.controller}/${reqModel.action}`
  if (reqModel.queryString && Object.keys(reqModel.queryString).length != 0) {
    let queryString = reqModel.queryString
    if (reqModel.queryString instanceof Object) {
      // console.log(_self, _self.$qs)
      queryString = qs.stringify(reqModel.queryString)
    }
    url += '?' + queryString
  }
  return url
}

const request = (req) => {
  return new Promise((resolve, reject) => {
    let reqModel = {
      method: String,
      // version: 1.0,
      urlWithVersion: false,
      timeout: 15 * 1000,
      controller: String,
      action: String,
      queryString: Object,
      data: Object,
      options: Object,
      auth: Boolean
    }
    let options = {}
    Object.assign(options, req.options)
    Object.assign(reqModel, req)
    let url = getApiUrl(reqModel)
    console.log(url)
    axios.request({
      url: url,
      method: reqModel.method || 'POST',
      headers: options.headers,
      data: reqModel.data,
      withCredentials: true
    }).then((res) => {
      if (res.data.ResultStatus === 'Fail') {
        let errorMsg = res.data.Message
        reject(new Error(errorMsg))
      } else {
        if (res.data.Data !== null && res.data.Data !== undefined) {
          resolve(res.data.Data)
        } else {
          resolve(res.data)
        }
      }
    }).catch((err) => {
      reject(new Error(err))
    })
  })
}

const requestRaw = async (req) => {
  let reqModel = {
    method: String,
    urlWithVersion: false,
    timeout: 15 * 1000,
    controller: String,
    action: String,
    queryString: Object,
    data: Object,
    options: Object,
    auth: Boolean
  }
  let options = {}
  Object.assign(options, req.options)
  Object.assign(reqModel, req)
  let url = getApiUrl(reqModel)
  return await axios.request({
    url: url,
    method: reqModel.method || 'POST',
    headers: options.headers,
    data: reqModel.data,
    withCredentials: true,
    responseType: req.responseType
  }).then((res) => {
    return Promise.resolve(res)
  }).catch((err) => {
    return Promise.reject(new Error(err))
  })
}

module.exports = {
  request,
  requestRaw,
  getApiUrl
} 
