export default {
  state: {
    pageHeader: '',
    pageDescr: '',
    pageNav: []
  },
  mutations: {
    SET_PAGE_STORE (state, data) {
      state.pageHeader = data.pageHeader
      state.pageDescr = data.pageDescr
      state.pageNav = data.pageNav
      localStorage.setItem('pageStore.pageHeader', data.pageHeader)
      localStorage.setItem('pageStore.pageDescr', data.pageDescr)
      localStorage.setItem('pageStore.pageNav', data.pageNav)
    },
    GET_PAGE_STORAGE (state) {
      state.pageHeader = localStorage.getItem('pageStore.pageHeader')
      state.pageDescr = localStorage.getItem('pageStore.pageDescr')
      state.pageNav = localStorage.getItem('pageStore.pageNav')
    }
  },
  actions: {
    setPageStore ({ commit }, data) {
      commit('SET_PAGE_STORE', data)
    },
    getPageStorage ({ commit }) {
      commit('SET_PAGE_STORE')
    }
  },
  getters: {
    pageHeader: state => {
      return state.pageHeader
    },
    pageDescr: state => {
      return state.pageDescr
    }
  }
}
