import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutContainer from '../views/Layout.vue'
import Home from '../views/Home.vue'
import configs from './../../configs'
let loginLayoutName = 'Login' + (configs('loginLayoutName') || '') + '.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LayoutContainer',
    component: LayoutContainer,
    redirect: '/home',
    children: [
      {
        name: 'Home',
        path: '/home',
        component: Home,
        meta: { 
          keepAlive: true,
          tagName: '首页'
        }
      },
      {
        name: 'Monitor',
        path: '/monitor',
        component: () => import(`../views/Monitor.vue`),
        meta: { 
          keepAlive: true,
          tagName: '监控中心'
        }
      },
      {
        name: 'Profile',
        path: '/profile',
        component: () => import(`../views/user/UserProfile.vue`),
        meta: { 
          keepAlive: true,
          tagName: '我的'
        }
      },
      {
        name: 'UserList',
        path: '/user/list',
        component: () => import(`../views/user/UserList.vue`),
        meta: { 
          keepAlive: true,
          tagName: '用户列表'
        }
      },
      {
        name: 'UserNew',
        path: '/user/new',
        component: () => import(`../views/user/UserForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '新增用户'
        }
      },
      {
        name: 'UserEdit',
        path: '/user/edit/:id',
        component: () => import(`../views/user/UserForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '修改用户'
        }
      },
      {
        name: 'ProjectList',
        path: '/projects/list',
        component: () => import(`../views/project/ProjectList.vue`),
        meta: { 
          keepAlive: true,
          tagName: '项目列表'
        }
      },
      {
        name: 'ProjectNew',
        path: '/projects/new',
        component: () => import(`../views/project/ProjectNew.vue`),
        meta: { 
          keepAlive: true,
          tagName: '新建项目'
        }
      },
      {
        name: 'ProjectEdit',
        path: '/projects/edit/:tid',
        component: () => import(`../views/project/ProjectNew.vue`),
        meta: { 
          keepAlive: true,
          tagName: '修改项目'
        }
      },
      {
        name: 'ProjectDetail',
        path: '/projects/view/:tid',
        component: () => import(`../views/project/ProjectDetail.vue`),
        meta: { 
          keepAlive: true,
          tagName: '项目看板'
        },
        children: [
          {
            name: 'ProjectWorkders',
            path: '/projects/view/:tid/workers',
            component: () => import(`../views/project/ProjectWorkers.vue`),
            meta: { 
              keepAlive: false,
              tagName: '务工人员列表'
            }
          }
        ]
      },
      {
        name: 'ComplaintList',
        path: '/complaint/list',
        component: () => import(`../views/complaint/List.vue`),
        meta: { 
          keepAlive: true,
          tagName: '投诉列表'
        }
      },
      {
        name: 'AlarmList',
        path: '/alarm/list',
        component: () => import(`../views/alarm/List.vue`),
        meta: { 
          keepAlive: true,
          tagName: '风险预警列表'
        }
      },
      {
        name: 'Tags',
        path: '/tags/list',
        component: () => import(`../views/tags/TagsList.vue`),
        meta: { 
          keepAlive: true,
          tagName: '标签列表'
        }
      },
      {
        name: 'IllegalReleaseList',
        path: '/illegal-release/list',
        component: () => import(`../views/supervision/IllegalReleaseList.vue`),
        meta: { 
          keepAlive: true,
          tagName: '重大违法公开列表'
        }
      },
      {
        name: 'IllegalReleaseAdd',
        path: '/illegal-release/add',
        component: () => import(`../views/supervision/IllegalReleaseForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '新增重大违法公开'
        }
      },
      {
        name: 'IllegalReleaseEdit',
        path: '/illegal-release/edit/:tid',
        component: () => import(`../views/supervision/IllegalReleaseForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '修改重大违法公开'
        }
      },
      {
        name: 'JointPunishmentList',
        path: '/joint-punishment/list',
        component: () => import(`../views/supervision/JointPunishmentList.vue`),
        meta: { 
          keepAlive: true,
          tagName: '失信联合惩戒名单'
        }
      },
      {
        name: 'JointPunishmentAdd',
        path: '/joint-punishment/add',
        component: () => import(`../views/supervision/JointPunishmentForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '新增失信惩戒'
        }
      },
      {
        name: 'JointPunishmentEdit',
        path: '/joint-punishment/edit/:tid',
        component: () => import(`../views/supervision/JointPunishmentForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '修改失信惩戒'
        }
      },
      {
        name: 'RegulationList',
        path: '/regulation/list',
        component: () => import(`../views/supervision/RegulationList.vue`),
        meta: { 
          keepAlive: true,
          tagName: '法规政策列表'
        }
      },
      {
        name: 'RegulationAdd',
        path: '/regulation/add',
        component: () => import(`../views/supervision/RegulationForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '新增法规政策'
        }
      },
      {
        name: 'RegulationEdit',
        path: '/regulation/edit/:tid',
        component: () => import(`../views/supervision/RegulationForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '修改法规政策'
        }
      },
      {
        name: 'ComplaintTelList',
        path: '/complaint-tel/list',
        component: () => import(`../views/supervision/ComplaintTelList.vue`),
        meta: { 
          keepAlive: true,
          tagName: '投诉渠道列表'
        }
      },
      {
        name: 'ComplaintTelAdd',
        path: '/complaint-tel/add',
        component: () => import(`../views/supervision/ComplaintTelForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '新增投诉渠道'
        }
      },
      {
        name: 'ComplaintTelEdit',
        path: '/complaint-tel/edit/:tid',
        component: () => import(`../views/supervision/ComplaintTelForm.vue`),
        meta: { 
          keepAlive: true,
          tagName: '修改投诉渠道'
        }
      },
      {
        name: 'GeneralComplaintList',
        path: '/general-complaint/list',
        component: () => import(`../views/supervision/GeneralComplaintList.vue`),
        meta: { 
          keepAlive: true,
          tagName: '欠薪线索列表'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(`../views/${loginLayoutName}`)
  }
]

const router = new VueRouter({
  routes
})

export default router
