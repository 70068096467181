import base from './base'
import {base64} from './../utils/common'

let controller = 'zztAuth'
let action = null

class Auth {
  constructor (vueInst) {
    this.vue = vueInst
  }
  async login (userItem) {
    action = 'Login'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: userItem
    }
    return await base.requestRaw(reqModel).then((res) => {
      console.log(res)
      let payload = res.data
      if (payload.ResultCode !== 1) {
        return Promise.reject(new Error(payload.Message))
      }
      this.vue.$store.dispatch('setToken', payload.Data.Token)
      console.log(payload.Data)
      return Promise.resolve(payload.Data)
    }).catch(err => {
      return Promise.reject(err)
    })
  }
  
  async logout () {
    action = 'Logoff'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      auth: true
    }
    return base.request(reqModel)
  }

  async renew () {
    action = 'Renew'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      auth: true
    }
    return base.request(reqModel)
  }

  async myInfo () {
    action = 'GetMyInfo'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: null,
      auth: true
    }
    return base.request(reqModel)
  }

  async getUsersByPermission (filter, pageIndex = 0, pageSize = 20) {
    // 0x01=所有权限（所有用户）
    action = `GetUsersByPermissionInPage/${pageIndex}/${pageSize}`
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: filter,
      auth: true
    }
    return base.request(reqModel)
  }

  async getUserBriefListByPermission (permission = 1) {
    // 0x01=所有权限（所有用户）
    action = 'GetUserBriefListByPermission'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: {
        Permission: permission
      },
      auth: true
    }
    return base.request(reqModel)
  }

  async changeUserStatus (username, isEnable = true) {
    let stateAction = isEnable ? 'Enable' : 'Disable'
    action = `ChangeAccountStatus/${stateAction}/${base64(username)}`
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: null,
      auth: true
    }
    return base.request(reqModel)
  }

  async unbindWx (username) {
    action = `UnBindWx/${base64(username)}`
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: null,
      auth: true
    }
    return base.request(reqModel)
  }

  async addUser (userItem) {
    action = 'Register/Person'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: userItem,
      auth: true
    }
    return base.request(reqModel)
  }

  async innerRegister (userItem) {
    action = 'InnerRegister'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: userItem,
      auth: true
    }
    return await base.requestRaw(reqModel).then((res) => {
      let payload = res.data
      if (payload.indexOf('err') === 0) {
        return Promise.reject(new Error(payload.substr(4)))
      }
      console.log(payload)
      return Promise.resolve(payload)
    }).catch(err => {
      return Promise.reject(err)
    })
  }

  async modifyUser (userItem) {
    action = 'Modify'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: userItem,
      auth: true
    }
    return base.request(reqModel)
  }

  async changePassword (model) {
    let request = {
      Username: model.Username,
      OldPasswordMd5: model.OldPasswordMd5,
      NewPasswordMd5: model.NewPasswordMd5
    }

    action = 'ChangePassword'
    let reqModel = {
      urlWithVersion: false,
      method: 'POST',
      controller: controller,
      action: action,
      data: request,
      auth: true
    }
    return base.request(reqModel)
  }
}

export default Auth