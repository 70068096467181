const copyToClip = (content, message) => {
  var aux = document.createElement('input')
  aux.setAttribute('value', content)
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
  if (message === null) {
    alert('复制成功')
  } else {
    alert(message)
  }
}

const transformNumberIntoCHS = (number) => {
  var numReg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
  var testResult = numReg.test(number)
  if (!testResult || number === '0' || number === 0) {
    return ''
  }
  var unit = '仟佰拾亿仟佰拾万仟佰拾元角分'
  var str = ''
  number += '00'
  var point = number.indexOf('.')
  if (point >= 0) {
    number = number.substring(0, point) + number.substr(point + 1, 2)
  }
  unit = unit.substr(unit.length - number.length)
  for (var i = 0; i < number.length; i++) {
    str += '零壹贰叁肆伍陆柒捌玖'.charAt(number.charAt(i)) + unit.charAt(i)
  }
  return str.replace(/零(仟|佰|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整')
}

const convertImgToBase64 = (url, callback, outputFormat) => {
  var canvas = document.createElement('CANVAS')
  var ctx = canvas.getContext('2d')
  var img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = function () {
    canvas.height = img.height
    canvas.width = img.width
    ctx.drawImage(img, 0, 0)
    var dataURL = canvas.toDataURL(outputFormat || 'image/png')
    callback.call(this, dataURL)
    canvas = null
  }
  img.src = url
}

const base64 = (input) => {
  var _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_='
  var output = ''
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4
  var i = 0
  input = utf8Encode(input)
  while (i < input.length) {
    chr1 = input.charCodeAt(i++)
    chr2 = input.charCodeAt(i++)
    chr3 = input.charCodeAt(i++)
    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63
    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output = output +
      _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
      _keyStr.charAt(enc3) + _keyStr.charAt(enc4)
  }
  return output
}

const utf8Encode = (string) => {
  string = string.replace(/\r\n/g, '\n')
  var utftext = ''
  for (var n = 0; n < string.length; n++) {
    var c = string.charCodeAt(n)
    if (c < 128) {
      utftext += String.fromCharCode(c)
    } else if ((c > 127) && (c < 2048)) {
      utftext += String.fromCharCode((c >> 6) | 192)
      utftext += String.fromCharCode((c & 63) | 128)
    } else {
      utftext += String.fromCharCode((c >> 12) | 224)
      utftext += String.fromCharCode(((c >> 6) & 63) | 128)
      utftext += String.fromCharCode((c & 63) | 128)
    }
  }
  return utftext
}


const permissions = {
  '1': '基础权限',
  '2': '后台-管理员',
  '4': '后台-内容维护人员',
  '256': '担保公司-管理员',
  '512': '担保公司-业务员',
  '1024': '担保公司-风控',
  '2048': '担保公司-运营',
  '32768': '施工方-管理员',
  '65536': '施工方-管理层',
  '131072': '施工方-农民工',
  '4194304': '监管人'
}

const cityTier = {
  '1': '省级',
  '2': '地市级',
  '3': '县区级',
  '7': '乡镇级'
}

const companyType = {
  '0': '未知',
  '1': '总承包',
  '2': '专业承包',
  '3': '劳务分包',
  '4': '业主',
  '5': '工程监理',
  '6': '工程设计',
  '7': '工程造价咨询',
  '8': '规划编制'
}

const guarantorStatus = {
  '-1': '草稿',
  '0': '未备案',
  '1': '预审中',
  '2': '待审核',
  '3': '已备案',
  '40': '异常',
  '99': '退回'
}

const guarantorStatusColor = {
  '-1': 'warning',
  '0': 'default',
  '1': 'orange',
  '2': 'magenta',
  '3': 'success',
  '40': 'error',
  '99': 'error'
}

const guaranteeStatus = {
  '0': '准备中', // 已创建未提交
  '1': '风控审核中', // 已提交
  '2': '系统派单中', // 风控已批准
  '3': '成功出函', // 已有足够的担保
  '4': '关闭' // 风控拒绝或没有足够的担保
}

// 在保的状态
const undergoingStatus = [0, 1, 2]

const guaranteeStatusColor = {
  '0': 'magenta',
  '1': 'orange',
  '2': 'blue',
  '3': 'green',
  '4': 'default'
}

const generalComplaintStatus = {
  '0': '待处理',
  '1': '处理中',
  '2': '已解决',
  '3': '已关闭'
}

const generalComplaintStatusColor = {
  '0': 'blue',
  '1': 'orange',
  '2': 'green',
  '3': 'default'
}

const guaranteeVariety = {
  '0': '支付保函',
  '1': '履约保函',
  '2': '农民工工资保函',
  '3': '质量保函'
}

const guaranteeVarietyColor = {
  '0': 'blue',
  '1': 'orange',
  '2': 'cyan',
  '3': 'magenta'
}

const expertType = {
  '0': '注册监理工程师',
  '1': '注册建造师'
}

const contractorType = {
  '1': '总承包',
  '2': '专业承包',
  '3': '劳务'
}

const guarantorType = {
  '1': '担保公司',
  '2': '国有银行',
  '3': '股份制银行',
  '10': '保险公司',
  '99': '其他'
}

const gender = {
  '0': '女',
  '1': '男'
}

const genderColor = {
  '0': 'magenta',
  '1': 'blue'
}

const projectStatus = {
  '0': '计划',
  '1': '在建',
  '2': '竣工',
  '3': '停工'
}

const projectStatusColor = {
  '0': 'blue',
  '1': 'green',
  '2': 'default',
  '3': 'magenta'
}

const projectType = {
  '1': '房屋建筑工程',
  '2': '其他工程'
}


const getEnumSelect = (enums) => {
  let list = []
  for (var p in enums) {
    list.push({
      key: p,
      value: enums[p]
    })
  }
  return list
}

module.exports = {
  copyToClip,
  transformNumberIntoCHS,
  convertImgToBase64,
  base64,
  utf8Encode,
  permissions,
  cityTier,
  companyType,
  expertType,
  contractorType,
  guarantorType,
  getEnumSelect,
  guaranteeStatus,
  undergoingStatus,
  gender,
  genderColor,
  guaranteeStatusColor,
  guaranteeVariety,
  guaranteeVarietyColor,
  guarantorStatus,
  guarantorStatusColor,
  projectStatus,
  projectStatusColor,
  projectType,
  generalComplaintStatus,
  generalComplaintStatusColor
}
