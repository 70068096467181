<template>
  <div class="layout-container">
    <Layout class="layout">
      <Sider collapsible class="left-menu" theme="dark" width="248" :hide-trigger="true" :collapsed-width="78" v-model="isCollapsed" :style="{background: '#081c27', overflowY: 'hidden', overflowX: 'hidden', position: 'relative'}">
        <div class="layout-logo-left" :class="{collapsed:isCollapsed}" @click="$router.push({path:'/'})">
          <img src="../assets/c.png" :width="isCollapsed?48:67">
        </div>
        <div class="line-nav" v-if="!isCollapsed">
          <span>功能导航</span>
        </div>
        <Divider v-else />
        <vue-scroll>
          <Menu class="menu-left" theme="dark" width="auto" :accordion="true" :class="menuitemClasses">
            <section v-for="(menu,idx) in leftMenu" :key="idx">
              <MenuItem :name="menu.name" :to="menu.link" v-if="(!menu.children||!menu.children.length)&&(!menu.roles||checkRolePermitted(menu.roles))">
                <Icon :type="menu.icon" v-if="menu.icon" />
                {{isCollapsed?'':menu.title}}
              </MenuItem>
              <Dropdown placement="right" :transfer="true" v-else-if="isCollapsed&&(!menu.roles||checkRolePermitted(menu.roles))">
                <MenuItem :name="menu.name">
                  <Icon :type="menu.icon" v-if="menu.icon"></Icon>
                  <Icon type="ios-arrow-forward" class="left-menu-arrow"></Icon>
                </MenuItem>
                <DropdownMenu slot="list">
                  <DropdownItem v-for="(sub,sidx) in menu.children.filter((c)=>{return !c.roles||checkRolePermitted(c.roles)})" :key="idx+'-sub-'+sidx"><router-link :to="sub.link">{{sub.title}}</router-link></DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Submenu :name="menu.name" v-else-if="!menu.roles||checkRolePermitted(menu.roles)">
                <template slot="title">
                  <Icon :type="menu.icon" v-if="menu.icon"></Icon>
                  <span>{{menu.title}}</span>
                </template>
                <MenuItem :name="sub.name" :to="sub.link" v-for="(sub,sidx) in menu.children.filter((c)=>{return !c.roles||checkRolePermitted(c.roles)})" :key="idx+'-sub-'+sidx">{{sub.title}}</MenuItem>
              </Submenu>
            </section>
          </Menu>
        </vue-scroll>
      </Sider>
      <Layout class="right-layout">
        <Header class="header">
          <div class="left-menu-trigger">
            <Tooltip :content="isCollapsed?'展开':'收起'">
              <Icon @click.native="isCollapsed=!isCollapsed" :style="{padding: '0 24px'}" type="md-menu" size="24"></Icon>
            </Tooltip>
          </div>
          <div class="top-bar">
            <div class="layout-nav">
            </div>
          </div>
          <div class="top-right">
            <div class="notis">
              <div class="notis-title">
                <Badge :dot="$store.getters.newMessages.length>0">
                  <a href="#">
                    <Icon type="ios-notifications" size="24"></Icon>
                  </a>
                </Badge>
              </div>
              <div class="notis-detail">
                <Row type="flex" class="notis-detail-main">
                  <Col span="24" style="justify-content:center;align-items: center;" v-if="$store.getters.newMessages.length>0">
                    <ul class="notis-list">
                      <li class="notis-item" v-for="(msg,msgIdx) in $store.getters.newMessages" :key="msgIdx">
                        <Row>
                          <Col span="12">
                            {{ msg.SenderName }}
                          </Col>
                          <Col span="12" style="text-align: right;" class="font-grey">
                            {{ getDateTimeDiff(msg.Time) }}
                          </Col>
                        </Row>
                        <Row>
                          <Col span="24" class="notis-item-content font-grey">
                            {{ msg.Content }}
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Col>
                  <Col span="24" style="display:flex;justify-content:center;align-items: center;" v-else>
                    <div class="font-grey" style="flex:1;text-align: center;">没有未读信息</div>
                  </Col>
                </Row>
                <div class="notis-detail-footer">
                  <Button type="text" shape="circle" long icon="ios-mail-outline" @click="$router.push({path:'/messages/inbox'})">查看收件箱</Button>
                </div>
              </div>
            </div>
            <span class="rt-line"></span>
            <div class="user-info">
              <div class="user-title">
                <img class="user-img" :src="getHeadImg()">
                <span class="user-name">{{ $store.getters.name || 'username' }}</span>
              </div>
              <div class="user-detail">
                <Row type="flex" class="user-detail-main">
                  <Col span="8" style="display:flex;justify-content:center;align-items: center;">
                    <img class="user-detail-img" :src="getHeadImg()">
                  </Col>
                  <Col span="16" style="justify-content:center;align-items: center;">
                    <div class="padding-top-10"></div>
                    <ul class="list-group list-group-unbordered">
                      <li class="list-group-item">
                        <b style="font-size:16px;margin-right:10px">{{ $store.getters.name || '---' }}</b>
                        <span class="">
                          <Tag :color="$common.genderColor[this.$store.getters.isMale?'1':'0']">{{ $common.gender[this.$store.getters.isMale?'1':'0'] }}</Tag>
                        </span>
                      </li>
                      <li class="list-group-item">
                        <span class="font-grey">
                          <Tag v-for="(perm,permIdx) in getPermissions()" :key="permIdx">{{perm}}</Tag>
                        </span>
                      </li>
                      <li class="list-group-item">
                        <span class="font-grey">注册日期：{{ $moment(this.$store.getters.creationTime).format('YYYY-MM-DD') }}</span>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <div class="user-detail-footer">
                  <Tooltip content="我的信息" placement="bottom">
                    <Button type="info" shape="circle" icon="ios-person" @click="$router.push({path:'/profile'})">我的信息</Button>
                  </Tooltip>
                  <Tooltip content="登出系统" placement="bottom" class="pull-right">
                    <Button type="error" shape="circle" icon="ios-log-out" @click="logOut"></Button>
                  </Tooltip>
                  <div style="clear:both;"></div>
                </div>
              </div>
            </div>
          </div>
        </Header>
        <div class="page-tags">
          <Tag :closable="tags.length>1" type="border"
            :color="isCurrentTag(tag) ? 'primary' : 'default'"
            v-for="(tag,idx) in tags"
            :key="idx"
            @on-close="closeTag(tag)">
            <span style="cursor: pointer" @click="switchTag(tag)">
              {{tag.meta.tagName}}
            </span>
          </Tag>
        </div>
        <Layout class="main-layout">
          <Content :style="{padding: '0px 16px 0px 16px', minHeight: '280px', background: 'transparent', overflow: 'hidden auto'}">
            <keep-alive :include="keepAliveTags">
              <router-view />
            </keep-alive>
          </Content>
          <!-- <Footer /> -->
        </Layout>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import moment from 'moment'
import configs from './../../configs'
// import Footer from '@/components/Footer.vue'
export default {
  name: 'LayoutContainer',
  components: {
  },
  data () {
    return {
      tags: [],
      isCollapsed: false,
      leftMenu: [
        {
          name: 'home',
          icon: 'ios-home',
          title: '主页',
          link: '/home', // 如果没有子菜单则应显示为链接
        },
        {
          name: 'monitor',
          icon: 'md-desktop',
          title: '监控中心',
          link: '/monitor', // 如果没有子菜单则应显示为链接
        },
        {
          name: 'projects',
          icon: 'ios-keypad',
          title: '项目中心',
          link: null, // 如果没有子菜单则应显示为链接
          children: [
            {
              name: 'project-list',
              title: '项目列表',
              link: '/projects/list'
            },
            {
              name: 'project-new',
              title: '新增项目',
              link: '/projects/new',
              roles: ['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营']
            }
          ]
        },
        {
          name: 'supervision',
          icon: 'ios-briefcase-outline',
          title: '劳动监察',
          link: null, // 如果没有子菜单则应显示为链接
          roles: ['后台-管理员','后台-内容维护人员','监管人'],
          children: [
            {
              name: 'general-complaint-list',
              title: '欠薪线索反映',
              link: '/general-complaint/list',
              roles: ['后台-管理员','后台-内容维护人员','监管人']
            },
            {
              name: 'complaint-tel-list',
              title: '投诉渠道',
              link: '/complaint-tel/list',
              roles: ['后台-管理员','后台-内容维护人员','监管人']
            },
            {
              name: 'illegal-release-list',
              title: '重大违法公开',
              link: '/illegal-release/list',
              roles: ['后台-管理员','后台-内容维护人员','监管人']
            },
            {
              name: 'joint-punishiment-list',
              title: '联合惩戒名单',
              link: '/joint-punishment/list',
              roles: ['后台-管理员','后台-内容维护人员','监管人']
            },
            {
              name: 'regulation-list',
              title: '法规政策',
              link: '/regulation/list',
              roles: ['后台-管理员','后台-内容维护人员','监管人']
            },
          ]
        },
        {
          name: 'complaint-list',
          icon: 'ios-notifications',
          title: '欠薪投诉',
          link: '/complaint/list',
          roles: ['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营']
        },
        {
          name: 'alarm-list',
          icon: 'ios-alert-outline',
          title: '风险预警',
          link: '/alarm/list',
          roles: ['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营','监管人']
        },
        {
          name: 'users',
          icon: 'ios-person',
          title: '账号中心',
          link: null,
          roles: ['后台-管理员','后台-内容维护人员','担保公司-管理员'],
          children: [
            {
              name: 'user-list',
              title: '用户列表',
              link: '/user/list'
            },
            {
              name: 'user-new',
              title: '新增用户',
              link: '/user/new'
            }
          ]
        },
        {
          name: 'tags',
          icon: 'ios-settings',
          title: '系统设置',
          link: null,
          roles: ['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员'],
          children: [
            {
              name: 'tags-list',
              title: '标签',
              link: '/tags/list'
            }
          ]
        }
      ],
      renewTask: null
    }
  },
  computed: {
    keepAliveTags () {
      return this.$store.getters.keepAliveTags
    },
    menuitemClasses: function () {
      return [
        'menu-item',
        this.isCollapsed ? 'collapsed-menu' : ''
      ]
    }
  },
  watch: {
    '$route': function (val) {
      let exists = this.tags.filter((t) => {
        return t.name === this.$route.name
      })
      if (!exists || !exists.length) {
        this.tags.push(val)
      } else {
        if (exists.path !== val.path) {
          this.$nextTick(() => {
            this.tags.splice(this.tags.findIndex(o => o.name === this.$route.name), 1)
            this.tags.push(val)
          })
        }
      }
      if (val.meta.keepAlive) {
        this.$nextTick(() => {
          this.$store.dispatch('setKeepAliveTag', val.name)
        })
      }
    }
  },
  mounted () {
    this.initMyData()
    this.renewToken()
    let exists = this.tags.filter((t) => {
      return t.name === this.$route.name
    })
    if (!exists || !exists.length) {
      this.tags.push(this.$route)
    }
    if (this.$route.meta.keepAlive) {
      this.$store.dispatch('setKeepAliveTag', this.$route.name)
    }
  },
  methods: {
    renewToken () {
      this.renewTask = setInterval(() => {
        this.$Api.Auth(this).renew()
        .catch(err => {
          this.$Notice.error({
            title: '刷新Token失败!',
            desc: err.message
          })
        })
      }, 1000 * 60 * 2)
    },
    getConfig (key) {
      return configs(key)
    },
    getMoment (dt) {
      return moment(dt)
    },
    isCurrentTag (tag) {
      return this.$route.name === tag.name
    },
    switchTag (tag) {
      let path = tag.path
      if (path) {
        this.$router.push(path)
      }
    },
    closeTag (tag) {
      if (this.tags.length > 1) {
        this.$store.dispatch('removeKeepAliveTag', tag.name)
        let idx = this.tags.indexOf(tag)
        if (idx > -1) {
          this.tags.splice(idx, 1)
        }
        if (this.tags.length) {
          if (idx === 0) {
            this.$router.push(this.tags[idx].path)
          } else {
            this.$router.push(this.tags[idx - 1].path)
          }
        } else {
          this.$router.push('/home')
        }
      }
    },
    checkRolePermitted (roles) {
      if (!this.$store.getters.permission || !this.$store.getters.permissions.length) {
        return false
      }
      if (this.$store.getters.permission % 2 === 0) {
        // 后台-管理员
        return true
      }
      let userRoles = []
      this.$store.getters.permissions.forEach((p) => {
        userRoles.push(this.$common.permissions[p.toString()])
      })
      if (roles instanceof Array) {
        let inside = false
        roles.forEach((r) => {
          if (userRoles.indexOf(r) > -1) {
            inside = true
          }
        })
        return inside
      }
      return userRoles.indexOf(roles) > -1
    },
    async initMyData () {
      await this.$Api.Auth().myInfo().then((data) => {
        console.log(data)
        this.$store.dispatch('setUserStore', data.User)
      }).catch((err) => {
        if (err.message === '用户不存在') {
          this.$router.push({path: '/login'})
        }
      })
    },
    async logOut () {
      this.$Spin.show()
      await this.$Api.Auth(this).logout().then(() => {
        this.$Message.success('登出成功!')
        this.$Spin.hide()
        this.$store.dispatch('logout')
        this.$router.push({path: '/login'})
      }).catch(err => {
        this.$Spin.hide()
        this.$Message.error('登出失败!' + err.message)
        this.$store.dispatch('logout')
        this.$router.push({path: '/login'})
      })
    },
    getHeadImg () {
      let img = require('./../assets/libs/img/user1-128x128.jpg')
      if (!this.$store.getters.isMale) {
        img = require('./../assets/libs/img/user3-128x128.jpg')
      }
      return img
    },
    getPermissions () {
      let perms = []
      if (this.$store.getters.permissions) {
        this.$store.getters.permissions.forEach((p) => {
          perms.push(this.$common.permissions[p.toString()])
        })
      }
      return perms
    }
  },
  beforeDestroy () {
    if (this.renewTask) {
      clearInterval(this.renewTask)
    }
  }
}
</script>

<style lang="less" scoped>
@import './../theme/plain.less';
.layout-container {
  background: #fff;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  .layout {
    height: 100%;
    .right-layout {
      display: flex;
      flex-direction: column;
      .main-layout {
        flex: 1;
        overflow: hidden;
      }
    }
  }
}
.header {
  background: #fff;
  padding: 0;
}

.left-menu {
   width: 248px !important;
   .menu-item span{
    display: inline-block;
    overflow: hidden;
    width: 90px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
  }
  .menu-item i{
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
  }
  .collapsed-menu span{
    width: 0px;
    transition: width .2s ease;
  }
  .collapsed-menu i{
    transform: translateX(5px);
    transition: font-size .2s ease .2s, transform .2s ease .2s;
    vertical-align: middle;
    font-size: 22px;
  }
  .ivu-menu-item {
    .left-menu-arrow {
      position: absolute;
      color: #ccc;
      opacity: 0;
      transition: all ease-in-out .3s;
    }
    &:hover {
      background: #f2f2f2;
      .left-menu-arrow {
        opacity: 1;
      }
    }
  }
}

.menu-left {
  /*menu盒子*/

  &.ivu-menu-dark {
    background: #081c27;
  }
  /*二级标题点击样式*/

  &.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active,
  &.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active:hover {
    background: #39404e !important;
  }

  &.ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened {
    background: #081c27;
    color: #49b069;
  }
  
}
/*menu盒子logo样式*/

.layout-logo-left {
  cursor: pointer;
  border: 10px solid #268b97;
  width: 140px;
  height: 140px;
  text-align: center;
  padding: 18px;
  background: #5b6270;
  border-radius: 70px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
  background-image: -moz-linear-gradient(top, #03aa8e, #49b069);
  /*火狐*/
  background: -webkit-linear-gradient(#03aa8e 0%, #49b069 100%);
  background: -moz-linear-gradient(#03aa8e 0%, #49b069 100%);
  background: -o-linear-gradient(#03aa8e 0%, #49b069 100%);
  background: linear-gradient(#03aa8e 0%, #49b069 100%);
  /*Opera*/
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #03aa8e), color-stop(1, #49b069));
  /*Chrome*/
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#03aa8e', endColorstr='#49b069', GradientType='0');
  /*IE*/
  &.collapsed {
    border: 0;
    background: none;
    width: 58px;
    height: 58px;
    padding: 0px;
  }
}
/*menu分区标题*/

.line-nav {
  background: url('../assets/bor.png') no-repeat;
  background-position: 50% 50%;
}

.line-nav span {
  display: block;
  width: 100px;
  line-height: 50px;
  color: hsla(0, 0%, 100%, .7);
  margin: 0 auto;
  text-align: center;
  background: #081c27;
}

.left-menu-trigger {
  cursor: pointer;
  display: inline-block;
  &:hover {
    color: @success-color;
    cursor: pointer;
  }
}

.header {
  display: flex;
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
  position: relative;
  width: 100%;
  /*overflow: hidden;*/
  /*padding-right: 315px;*/
  z-index: 20;
  .top-bar {
    flex: 1;
  }
}

/*header盒子右边*/
.top-right a {
  color: #03aa8e;
}
.top-right {
  float: right;
  font-size: 14px;
  margin-right: 40px;
  /*line-height: 60px;*/
  color: #03aa8e;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rt-line {
  width: 1px;
  height: 30px;
  border-left: 1px solid #03aa8e;
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

.user-info, .notis {
  display: inline-block;
  position: relative;
}
.user-info:hover .user-title, .notis:hover .notis-title {
  /*background: linear-gradient(to bottom, rgba(38, 139, 151, .1), #fff);*/
  border-top: 4px solid #03aa8e;
  box-shadow: 0 -3px 5px rgba(0,0,0,.2);
  z-index: 51;
  background-color: #fff;
}
.user-info .user-title, .notis .notis-title {
  // /*border-top: 4px solid #fff;
  position: relative;
  display: flex;
  height: 60px;
  width: 140px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
  cursor: pointer;
}
.notis .notis-title {
  width: 60px;
}
.user-info .user-name {
  line-height: 32px;
  padding: 0 16px;
}
.user-info .user-img {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}
.user-info .user-detail, .notis .notis-detail {
  position: absolute;
  visibility: hidden;
  right: 0;
  top: 59px;
  opacity: 0;
  z-index: 50;
  box-shadow: 0 0px 8px rgba(0,0,0,.2);
  /*border: 1px solid #ccc;*/
  background: #fff;
  color: #888;
  font-size: 12px;
  transition: all .3s ease;
  width: 320px;
  min-height: 160px;
  display: flex;
  flex-direction: column;
}
.user-info:hover .user-detail, .notis:hover .notis-detail {
  visibility: visible;
  opacity: 1;
}
.user-info .user-detail .user-detail-main, .notis .notis-detail .notis-detail-main {
  flex: 1;
}
.notis .notis-detail .notis-detail-main {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
.user-info .user-detail-img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
.user-info .user-detail .user-detail-footer, .notis .notis-detail .notis-detail-footer {
  /*height: 40px;*/
  padding: 8px 16px;
}
.notis-list {
  transition: all .5s ease;
  position: relative;
}
.notis-list .notis-item {
  border: 1px solid #fff;
  border-bottom: 1px solid #f5f5f5;
  padding: 16px;
  background: #fff;
  transition: all .5s ease;
  cursor: pointer;
  position: relative;
}
.notis-list .notis-item:hover {
  /*border: 1px solid #03aa8e;*/
  /*background: linear-gradient(to top, rgba(38, 139, 151, .1), #fff);*/
  background: #fafafa;
}
.notis-list .notis-item-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-tags {
  padding: 5px 16px;
}
</style>

<style lang="less">
.ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),
.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu) {
  color: #fff !important;
}
</style>