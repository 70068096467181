<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import Vue from 'vue'
import Api from './api/Api'
export default {
  data () {
    return {
    }
  },
  mounted () {
    Vue.prototype.$Api = Api
  },
  methods: {
  }
}
</script>

<style lang="less">
@import './theme/plain.less';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: @text-color;
}
</style>
